import { get } from './http'

export function getLand(page) {
  // 房产开发
  return get(`/api/business/realEstate?page=${page}&pagesize=9`)
}

export function getBuilding() {
  // 建材贸易
  return get('/api/business/building')
}

export function getEstate() {
  // 物业商管
  return get('/api/business/property')
}

export function getLanDetail(bid) {
  return get(`/api/business/detail?bid=${bid}`)
}

export function getRecom() {
  // 地产详情的推荐轮播图
  return get('/api/business/recom')
}
