<!-- 业务详情 -->
<template>
  <div class="detail-container" style="background: #e5e5e5">
    <div class="container top-container mt-80-mb-140">
      <div class="row">
        <div class="col-12 col-xxl-12 col-xl-12 col-md-12 col-sm-12 detail-box">
          <div class="title">{{ detail.title }}</div>
          <div class="text" v-html="detail.content"></div>
          <div class="icon-img">
            <img :src="detail.logo" />
          </div>
        </div>
        <div
          class="col-xxl-6 col-xl-6 col-12 bottom-img col-sm-6"
          v-for="(img, index) in detail.images"
          :key="img"
          :class="{ 'mx-auto': detail.images.length === 1 }"
        >
          <div
            class="bottom-img-box"
            :class="{
              'float-end': index % 2 === 0,
              'float-start': index % 2 !== 0,
              'float-none': detail.images.length === 1
            }"
          >
            <img :src="img" class="d-block" />
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-bottom-bar d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-12 col-sm-12">
            <div class="swiper mySwiper position-relative">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="item in recom"
                  :key="item"
                  @click="clickSwiperItem(item)"
                >
                  <img :src="item.image" alt="" />
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, onMounted, ref, computed, onActivated, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getLanDetail, getRecom } from '@/server/business'
import Swiper from 'swiper'
import router from '../../../router'

const emit = defineEmits(['hideTab'])
const detail = ref({})
const swiperImages = ref([])
const recom = ref([])

const route = useRoute()
const bid = computed(() => route.query.bid)

onMounted(async () => {
  const r = await getLanDetail(bid.value)
  detail.value = r
  swiperImages.value = r.images
  await fetchRecom()

  initSwiper()
})
onActivated(() => {
  emit('hideTab')
})

watch(
  () => bid.value,
  async (newVal) => {
    if (newVal === undefined) {
      return
    }
    const r = await getLanDetail(newVal)
    detail.value = r
    swiperImages.value = r.images
    initSwiper()
    console.log('newVal', newVal)
  }
)

const fetchRecom = async () => {
  recom.value = await getRecom()
}
function initSwiper() {
  // eslint-disable-next-line no-unused-vars
  var swiper = new Swiper('.mySwiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 10
      }
    }
  })
}

const clickSwiperItem = (item) => {
  router.replace({
    path: '/BusinessSection/LandDetail',
    query: { bid: item.bid }
  })
}
</script>
<style lang="stylus" scoped>
// .swiper-slide
//   text-align: center;
//   font-size: 18px;
//   background: #fff;
//   /* Center slide text vertically */
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   -webkit-align-items: center;
//   align-items: center;
//   .swiper-slide img
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
.detail-container
  background: #fff
  .detail-box
    position relative
    padding-right 270px
    @media screen and (max-width 768px) {
      padding-right 12px
      padding-top 10px
    }
    .title
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #10366B;
      margin-bottom 20px
      line-height 1.2
      @media screen and (max-width 768px) {
        font-size: 26px;
        margin-bottom 10px
      }
    .text
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
      @media screen and (max-width 768px) {
         font-size: 14px;
         line-height: 20px;
      }
    .icon-img
      width: 230px;
      height: 230px;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      position absolute
      right 0
      top 3px
      border 1px solid #e5e5e5
      img
        width 100%
        height 100%
        border-radius: 10px;
        object-fit cover
      @media screen and (max-width 768px) {
        margin 10px auto
        right auto
        position static
        top auto
        max-width 100%
        height auto
        width auto
      }
  .swiper-bottom-bar
    background url('../../../assets/29.png') no-repeat
    background-position bottom center
    background-size cover
    height 334px
    width 100%
    .swiper
      width 100%
      overflow hidden
      .swiper-wrapper
        .swiper-slide
          cursor pointer
          background none
          text-align: center;
          font-size: 18px;
          background: #fff;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          img
            width 100%
            height 200px
            border 4px solid rgba(16, 54, 107, .5)
            object-fit cover
.bottom-img
  margin-bottom 30px
  .bottom-img-box
    border 5px solid #fff
    width 550px
    height 360px
    overflow hidden
    max-width 100%
    img
      width 100%
      height 100%
      object-fit contain

// .swiper-button-prev
//   left -25px
// .swiper-button-next
//   right -25px
</style>
